import {Urls} from '../constants/'
import 'styles/participant-dashboard.scss'

//console.log(Urls.FmsURL);

(function(){
    /* global $ */
    'use strict';

    var thisrole;
    var pplRoleId = '';
    var transferuser = false;
    var providerType = '';
    var programName = '';
    
    const caseManagerRoleID = 'ce9c233a-cdde-41c4-bdd3-8b8a3cede36d';
    const caseManagerAdminRoleID = '781e55e1-43da-41da-a59b-29d2d28be586';
    
    function searchThis() {
        
        $('.js-hide-dashboard').show();
        // Get roles
        $.getJSON('/s4s/api/PPL/GetPPLRoles').done(function (data) {

            thisrole = 'employer';

            var urlDashAPI = '/s4s/api/Enrollment/SummaryParameters';
            $.getJSON(urlDashAPI, getEnrollment);

            $('.pcg-header__item.pcg-header__item--first').after(
                '<li class="pcg-header__item"><a class="pcg-skip-button" href="/s4s/ProviderInvoices">Invoices</a></li>'
            )
       
        })
        .fail(function() {

          //  console.log( 'This user is an employee' );
            thisrole = 'employee';

            var urlDashAPI = '/s4s/api/Enrollment/SummaryParameters';
            $.getJSON(urlDashAPI, getEnrollment);

          
        });

        
    }



    function getProviderTiles(data, providerType) {

        // California providers PPL-1004 Update Timesheets tile on Provider dashboard to be Earnings for CA
        if ( data.Data[0].Name === 'CA SDP') {

            $('.steps-list-container').append(
                '<div class="dbCards continue-card">' +
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                        '<i class="fas fa-check"></i>' +
                        '<h1>Time Entries and Earnings</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'   
            )
        }        
        // Tennessee ECF or Tennessee VSHP or Tennessee AC or Tennessee AG
        else if ( data.Data[0].Name === 'Tennessee ECF' || data.Data[0].Name === 'Tennessee VSHP' || data.Data[0].Name === 'Tennessee AC' ||
            data.Data[0].Name === 'Tennessee AG') {

            $('.steps-list-container').append(
                '<div class="dbCards continue-card">' +
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                        '<i class="fas fa-check"></i>' +
                        '<h1>Time Entries and Earnings</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'   
            )        
        }

        else if (data.Data[0].Name === 'CCC +') {

            $('.steps-list-container').append(
                '<div class="dbCards continue-card">' +
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                        '<i class="fas fa-check"></i>' +
                        '<h1>Time Entries and Earnings</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'   
            )
        }

        // Kansas
        else if (data.Data[0].Name === 'KS STEPS') {

            if ( $.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== '101364f3-1bf8-4f12-83c9-ad2f00947631' ) {
                $('.steps-list-container').append(
                    '<div class="dbCards continue-card">' +
                        '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                            '<i class="fas fa-check"></i>' +
                            '<h1>Time Entries and Earnings</h1>' +
                            '<span class="card-btn">View</span>' +
                        '</a>' +
                    '</div>'   
                )
            }

        } 
        
        // Maryland
        else if (data.Data[0].Name === 'Maryland DDA') {

            $('.steps-list-container').append(
                '<div class="dbCards continue-card">' +
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                        '<i class="fas fa-check"></i>' +
                        '<h1>Time Entries and Earnings</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'   
            )

        } else {

            // Independant contractors and Agency users shouldnt see Timesheets
            if ( (providerType !== '94f84bd5-e966-46f8-b545-aa29bd4f4e9e' || data.Data[0].Name !== 'Alabama DMH') ) {
                // Temporarily add "My timesheets" for "VA CCC +" Providers, as use of Budget Tool for 3.18 was moved to 3.20 - Bug 224035
                //if(data.Data[0].Name !== 'CCC +'){
                $('.steps-list-container').append(
                    '<div class="dbCards continue-card">' +
                        '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/EVVTImeEntryProvider" target="_blank">' +
                            '<i class="fas fa-check"></i>' +
                            '<h1>My timesheets</h1>' +
                            '<span class="card-btn">View</span>' +
                        '</a>' +
                    '</div>'   
                )
              //}
            }
        }


        if ( $.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== '101364f3-1bf8-4f12-83c9-ad2f00947631' ) {
            if ( providerType !== '94f84bd5-e966-46f8-b545-aa29bd4f4e9e' && data.Data[0].Name !== 'Maryland DDA' ) {
                $('.steps-list-container').append(
                    '<div class="dbCards continue-card">' +
                        '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/W2" target="_blank">' +
                            '<i class="fas fa-check"></i>' +
                            '<h1>My W-2</h1>' +
                            '<span class="card-btn">View</span>' +
                        '</a>' +
                    '</div>'
                )
            }
        }

        if ( data.Data[0].Name === 'Tennessee ECF' || data.Data[0].Name === 'Tennessee VSHP' || data.Data[0].Name === 'Tennessee AC' ||
             data.Data[0].Name === 'Tennessee AG' || data.Data[0].Name === 'Tennessee Katie Beckett') {

            $('.steps-list-container').append(
                '</div>' +
                '<div class="dbCards continue-card">' +
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_pplConnectMessages + '">' +    
                        '<i class="fas fa-check"></i>' +
                        '<h1>PPL Connect</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'
            )
        }

        if ( data.Data[0].Name === 'Rhode Island') {

            $('.steps-list-container').append(
                //'<div class="dbCards continue-card">' +
                //    '<a href="/s4s/CustomPage/Index/' + Urls.page_myCourses + '">' +
                //        '<i class="fas fa-check"></i>' +
                //        '<h1>My courses</h1>' +
                //        '<span class="card-btn">View</span>' +
                //    '</a>' +
                '</div>' +
                '<div class="dbCards continue-card">' +
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_pplConnectMessages + '">' +    
                        '<i class="fas fa-check"></i>' +
                        '<h1>PPL Connect</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'
            )
        }

        // Kansas
        if ( $.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== '101364f3-1bf8-4f12-83c9-ad2f00947631' ) {
            if ( data.Data[0].Name === 'KS STEPS') {

                $('.steps-list-container').append(
                    '<div class="dbCards continue-card">' +
                        '<a href="/s4s/CustomPage/Index/' + Urls.page_pplConnectMessages + '">' +    
                            '<i class="fas fa-check"></i>' +
                            '<h1>PPL Connect</h1>' +
                            '<span class="card-btn">View</span>' +
                        '</a>' +
                    '</div>'
                )
            }
        }

        // Virginia
        // Temporarily remove Archived timesheets for "VA CCC +" Providers, as use of Budget Tool for 3.18 was moved to 3.20 - Bug 224035
        // if ( data.Data[0].Name === 'CCC +') {
        //     $('.steps-list-container').append(
        //             '<div class="dbCards continue-card">' +
        //                 '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
        //                     '<i class="fas fa-check"></i>' +
        //                     '<h1>Archived timesheets</h1>' +
        //                     '<span class="card-btn">View</span>' +
        //                 '</a>' +
        //             '</div>'   
        //         )
        // }

        // Alabama
        if ( data.Data[0].Name === 'Alabama DMH') {
            $('.steps-list-container').append(
                '<div class="dbCards continue-card">' +
                    '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/Invoices" target="_blank">' +
                    '<i class="fas fa-check"></i>' +
                        '<h1>My invoices</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'
            )
        }

        // California
        if ( data.Data[0].Name === 'CA SDP') {
            $('.steps-list-container').append(
                '<div class="dbCards continue-card">' +
                    '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/Invoices" target="_blank">' +
                    '<i class="fas fa-check"></i>' +
                        '<h1>My invoices</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'
            )
        }

        // if ( data.Data[0].Name === 'CA SDP') {

        // // Independent provider type
        // //if ( (providerType === '13fd06d4-112a-40db-bf90-eee6a3ac36db') ) {
        //     $('.steps-list-container').append(
        //         '<div class="dbCards continue-card">' +
        //             '<a href="/s4s/ProviderInvoices" target="_blank">' +
        //                 '<i class="fas fa-check"></i>' +
        //                 '<h1>My invoices</h1>' +
        //                 '<span class="card-btn">View</span>' +
        //             '</a>' +
        //         '</div>'
        //     )
        // //}
        // }

        // Show paystubs but not for Kansas
        // Temporarily add "My paystubs" for "VA CCC +" Providers, as use of Budget Tool for 3.18 was moved to 3.20 - Bug 224035
        //     && data.Data[0].Name !== 'CCC +'
        if (data.Data[0].Name !== 'KS STEPS' && data.Data[0].Name !== 'CA SDP' && data.Data[0].Name !== 'Tennessee ECF' &&
            data.Data[0].Name !== 'Tennessee VSHP' && data.Data[0].Name !== 'Tennessee AC' && data.Data[0].Name !== 'Tennessee AG' && data.Data[0].Name !== 'Maryland DDA') {
            $('.steps-list-container').append(
                '<div class="dbCards continue-card">' +
                    '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/ProviderPayroll" target="_blank">' +
                        '<i class="fas fa-check"></i>' +
                        '<h1>My paystubs</h1>' +
                        '<span class="card-btn">View</span>' +
                    '</a>' +
                '</div>'
            )
        }


        

    }
    

    function checkAuth() {


    // public const string ROLE_TYPE_ID_EMPLOYER_STRING = "9b83f050-47e7-44c6-b535-468fcca8dd42";
    // public const string ROLE_TYPE_ID_EMPLOYEE_STRING = "112c3cc7-2c81-4702-a988-ec2c1bb03cb9";
    // public const string ROLE_TYPE_ID_AUTHREP_STRING = "0062582f-f438-4c71-8626-8a1fec2a637c";

  
    // //virtual role: Business org admin and sags
    // public const string ROLE_TYPE_ID_STAFF_STRING = "ec76b2c7-9798-45a9-a502-1bd3b1913a0a";
    // public const string ROLE_TYPE_ID_SAG_STRING = "22a21fd3-9b01-4c07-a8a9-e70d17fbb4da";
    // public const string ROLE_TYPE_ID_LA_STAFF_STRING = "4fa0aee3-ff4b-4940-b914-3c0bb36380e2";
    // public const string ROLE_TYPE_ID_CASE_MANAGER_STRING = "ce9c233a-cdde-41c4-bdd3-8b8a3cede36d";
    // public const string ROLE_TYPE_ID_CASE_MANAGER_ADMIN_STRING = "781e55e1-43da-41da-a59b-29d2d28be586";

    // public const string ROLE_TYPE_ID_EXTERNAL_SYSTEM_STRING = "e29b8f86-ee1e-4915-a620-56a792ec32de";

    // public const string ROLE_TYPE_ID_SUPPORT_BROKER_STRING = "05ec467c-6935-48a6-9b51-5b296b22f098";
    // public const string ROLE_TYPE_ID_SERVICE_FACILITATOR_STRING = "3915a945-7676-4d43-bf72-41769ed07196";


    

        //   if ( data.PplRoleId === '9b83f050-47e7-44c6-b535-468fcca8dd42') {
        //         $('body').addClass('ppl-employer-role');
        //     }
        //     if ( data.PplRoleId === '112c3cc7-2c81-4702-a988-ec2c1bb03cb9') {
        //         $('body').addClass('ppl-employee-role');
        //     }
        //     if ( data.PplRoleId === '0062582f-f438-4c71-8626-8a1fec2a637c') {
        //         $('body').addClass('ppl-auth-rep-role');
        //     }
        //     if ( data.PplRoleId === 'a69fabea-fa94-47b5-b438-2ead07f1b974') {
        //         $('body').addClass('ppl-virtual-employer-role');
        //     }
        //     if ( data.PplRoleId === '22a21fd3-9b01-4c07-a8a9-e70d17fbb4da') {
        //         $('body').addClass('ppl-staff-role');
        //     }
        //     if ( data.PplRoleId === 'a10d2183-fd39-4b1f-86f2-75cb89902a70') {
        //         $('body').addClass('ppl-staff-bm-role');
        //     }
        
        

        $.getJSON('/s4s/Auth/GetUserInfoJson').done(function (data) {

            // Look for provider management
            //if ( $.sc.authSettings.isEmployee === true) {

            //    for ( var ii = 0; ii < data.AvailableModules.length; ii++ ) {
            //        console.log('data.AvailableModules[ii] is: ' + data.AvailableModules[ii]);
            //        if ( data.AvailableModules[ii] === 'provider_management_administration') {
            //            window.location = '/s4s/ProviderInvoices'; 
            //        }
            //    }
            //}

            var orgId = data.OrganisationId;
            var originalRoleID = $.sc.authSettings.originalPrincipalPPLRole;
            
            if (originalRoleID == null)
              originalRoleID = '';

            // Participant role
            if ( data.PplRoleId === '9b83f050-47e7-44c6-b535-468fcca8dd42') {

                console.log('Participant')

                let migrateduser = false;

                // Is the user a transfer user?
                $.getJSON('/s4s/api/Enrollment/EmployerEnrollmentsList?page=0').done(function (enroll) {
                    
                    programName = '';

                    if ( enroll.Data.Items.length !== 0) {
                        var thisstatus = enroll.Data.Items[0].SourceType;
                        programName = enroll.Data.Items[0].Program;
                        thisstatus === 2 ? migrateduser = true : migrateduser;
                        
                    }


                    // Only show for Tenessee
                    if ( programName === 'Tennessee Katie Beckett') {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="/s4s/MyInvoices">' +
                                '<i class="fas fa-check"></i>' +
                                    '<h1>My invoices</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>'
                        )
                    }

                    // Kansas
                    if ( programName === 'KS STEPS') {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/SearchInvoices" target="_blank">' +
                                '<i class="fas fa-check"></i>' +
                                    '<h1>My invoices</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>'
                        )
                    }

                    // Only show for California users
                    if ( programName === 'CA SDP') {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/SearchInvoices" target="_blank">' +
                                '<i class="fas fa-check"></i>' +
                                    '<h1>My invoices</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>' +
                            '<div class="dbCards continue-card">' +
                                '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                                    '<i class="fas fa-check"></i>' +
                                    '<h1>My budget and time entries</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>' 
                        )
                    }

                    // Only show for Kansas users
                    if ( programName === 'KS STEPS') {

                        // Dont show for State admin users
                        if ( $.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== '101364f3-1bf8-4f12-83c9-ad2f00947631' ) {
                            $('.steps-list-container').append(
                                '<div class="dbCards continue-card">' +
                                    '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                                        '<i class="fas fa-check"></i>' +
                                        '<h1>My budget and time entries</h1>' +
                                        '<span class="card-btn">View</span>' +
                                    '</a>' +
                                '</div>' 
                            )
                        }
                    }   
                    // Only show for Tennessee ECF or Tennessee VSHP or Tennessee AC or Tennessee AG
                    if ( programName === 'Tennessee ECF' || programName === 'Tennessee VSHP' || programName === 'Tennessee AC' ||
                         programName === 'Tennessee AG') {
                      $('.steps-list-container').append(
                        '<div class="dbCards continue-card">' +
                            '<a href="/s4s/CustomPage/Index/' + Urls.page_pplConnect + '">' +
                                '<i class="fas fa-check"></i>' +
                                '<h1>PPL Connect</h1>' +
                                '<span class="card-btn">View</span>' +
                            '</a>' +
                        '</div>' +
                        '<div class="dbCards continue-card">' +
                            '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                                '<i class="fas fa-check"></i>' +
                                '<h1>My budget and time entries</h1>' +
                                '<span class="card-btn">View</span>' +
                            '</a>' +
                        '</div>' 
                      )
                    }
                    
                    // Only show for Tennessee Katie Beckett program participant
                    if ( programName === 'Tennessee Katie Beckett') {
                      $('.steps-list-container').append(
                        '<div class="dbCards continue-card">' +
                            '<a href="/s4s/CustomPage/Index/' + Urls.page_pplConnect + '">' +
                                '<i class="fas fa-check"></i>' +
                                '<h1>PPL Connect</h1>' +
                                '<span class="card-btn">View</span>' +
                            '</a>' +
                        '</div>'
                      )
                    }

                    

                    // Only show for Alabama users
                    if ( programName === 'Alabama DMH') {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/Invoices" target="_blank">' +
                                '<i class="fas fa-check"></i>' +
                                    '<h1>My invoices</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>'
                        )
                    }

                    // Only show for Rhode Island users
                    if ( programName === 'Rhode Island') {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="/s4s/CustomPage/Index/' + Urls.page_pplConnect + '">' +
                                    '<i class="fas fa-check"></i>' +
                                    '<h1>PPL Connect</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>' +
                            '<div class="dbCards continue-card">' +
                                '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                                    '<i class="fas fa-check"></i>' +
                                    '<h1>My budget and time entries</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>' 
                        )
                    }

                    // Only show for Alabama users
                    // if ( programName === 'Alabama DMH') {
                    //     $('.steps-list-container').append(
                    //         '<div class="dbCards continue-card">' +
                    //             '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                    //                 '<i class="fas fa-check"></i>' +
                    //                 '<h1>My budget and time entries</h1>' +
                    //                 '<span class="card-btn">View</span>' +
                    //             '</a>' +
                    //         '</div>' 
                    //     )
                    // }

                    if ( programName === 'Maryland DDA') {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                                    '<i class="fas fa-check"></i>' +
                                    '<h1>My budget and time entries</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>' 
                        )
                    }


                    // Only show for Virginia users
                    if ( programName === 'CCC +' || programName === 'Virginia CCC') {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/ParticipantCopay" target="_blank">' +
                                '<i class="fas fa-check"></i>' +
                                    '<h1>CO-Pay</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>' +
                             '<div class="dbCards continue-card">' +
                                 '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/ParticipantTimesheets" target="_blank">' +
                                 '<i class="fas fa-check"></i>' +
                                     '<h1>Archived Timesheets</h1>' +
                                     '<span class="card-btn">View</span>' +
                                 '</a>' +
                             '</div>'
                        //    '<div class="dbCards continue-card">' +
                        //        '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                        //            '<i class="fas fa-check"></i>' +
                        //            '<h1>My budget and time entries</h1>' +
                        //            '<span class="card-btn">View</span>' +
                        //        '</a>' +
                        //    '</div>' 
                        )
                    }
                    else if(programName !== 'Tennessee ECF' && programName !== 'OhioRISE' && programName !== 'Tennessee VSHP' &&
                            programName !== 'Tennessee AC' && programName !== 'Tennessee AG' && programName !=='Maryland DDA' && programName !== 'Alabama DMH' && 
                            programName === 'KS STEPS' && programName==='OHIO MyCare' && programName === 'Tennessee Katie Beckett')
                    {
                        // for all other programs
                        $('.steps-list-container').append(
                          '<div class="dbCards continue-card">' +
                              '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '#/copay">' +
                              '<i class="fas fa-check"></i>' +
                                  '<h1>CO-Pay</h1>' +
                                  '<span class="card-btn">View</span>' +
                              '</a>' +
                          '</div>'
                        )
                    }

                    // Only show for Kansas users ( State admin users cant view these )
                    if ( $.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== '101364f3-1bf8-4f12-83c9-ad2f00947631' ) {
                        if ( programName === 'KS STEPS') {
                            $('.steps-list-container').append(
                                '<div class="dbCards continue-card">' +
                                    '<a href="/s4s/CustomPage/Index/' + Urls.page_pplConnect + '">' +
                                        '<i class="fas fa-check"></i>' +
                                        '<h1>PPL Connect</h1>' +
                                        '<span class="card-btn">View</span>' +
                                    '</a>' +
                                '</div>'
                            )
                        }
                    }

                    //Only show for OhioRISE users
                    if ( programName === 'OhioRISE' ) {
                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="/s4s/CustomPage/Index/' + Urls.page_budgetManagement + '">' +
                                    '<i class="fas fa-check"></i>' +
                                    '<h1>My budget and time entries</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>' 
                        )

                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                                '<a href="/s4s/MyInvoices">' +
                                '<i class="fas fa-check"></i>' +
                                    '<h1>My invoices</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>'
                        )

                        $('.steps-list-container').append(
                            '<div class="dbCards continue-card">' +
                            '<a href="/s4s/CustomPage/Index/' + Urls.page_purchaseRequest + '">' +
                                '<i class="fas fa-check"></i>' +
                                    '<h1>My purchases</h1>' +
                                    '<span class="card-btn">View</span>' +
                                '</a>' +
                            '</div>'
                        )
                    }



                    // Show for everyone other than Kansas, VA, Tennessee ECF, OhioRISE, Tennessee VSHP,  Tennessee AC, Rhode Island
                    if (!(programName === 'KS STEPS' || programName === 'Tennessee ECF' || programName === 'OhioRISE' || programName === 'Tennessee VSHP' ||
                          programName === 'Tennessee AC' || programName === 'Tennessee AG' || programName === 'Rhode Island' || programName ==='Maryland DDA')) {

                        //Show Auth tile when it's not VA CM/CMA
                        if (!((programName === 'CCC +' || programName === 'Virginia CCC') &&
                            (originalRoleID == caseManagerRoleID || originalRoleID == caseManagerAdminRoleID)
                        ))
                        {
                            $('.steps-list-container').append(
                                '<div class="dbCards continue-card">' +
                                    '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/ServiceAuthorizations" target="_blank">' +
                                        '<i class="fas fa-check"></i>' +
                                        '<h1>My authorizations</h1>' +
                                        '<span class="card-btn">View</span>' +
                                    '</a>' +
                                '</div>');
                        }

                        $('.steps-list-container').append('<div class="dbCards continue-card">' +
                            '<a href="' + Urls.FmsURL + '/PPLPortal/SSO/EVVTimeEntryParticipant" target="_blank">' +
                                '<i class="fas fa-check"></i>' +
                                '<h1>My timesheets</h1>' +
                                '<span class="card-btn">View</span>' +
                            '</a>' +
                        '</div>');
                    }

                    if ( programName === 'CCC +' || programName === 'Virginia CCC')
                    {
                        getSupportTileTile();
                    }
                    


                    searchThis();

                    // Add in Jumbotron text

                    let migratedBullet = '<li class="pcg-homepage-jumbotron__item"><div class="pcg-rte-wrapper">Complete your Enrollment documents under My Programs</div></li>'; 
                    migrateduser === true ? migratedBullet = '' : migratedBullet;

                    $('.js-homepage-jumbotron').append(
                        '<p>Now that you have registered for MyAccount, please do the following items to complete your enrollment.</p>' +
                        '<ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.pplfirst.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">' +
                            '<li class="pcg-homepage-jumbotron__item">' +
                                '<div class="pcg-rte-wrapper">Add your electronic signature under My Signature</div>' +
                            '</li>' +
                            migratedBullet +
                            '<li class="pcg-homepage-jumbotron__item">' +
                                '<div class="pcg-rte-wrapper">Review your provider\'s enrollment under My Providers</div>' +
                            '</li>' +
                        '</ul>' +
                        '<p>Once these set up items are completed, you will be able to <strong>start!</strong></p>'
                    )
                    
                })

                


            } else if ( data.PplRoleId !== null  && data.PplRoleId !== '00000000-0000-0000-0000-000000000000' ) {

                console.log('Provider')

                pplRoleId = data.PplRoleId;

                // PPL staff redirect                                        La Staff role
                if ( pplRoleId === '22a21fd3-9b01-4c07-a8a9-e70d17fbb4da' || pplRoleId === '4fa0aee3-ff4b-4940-b914-3c0bb36380e2') {

                    if ( $.sc.authSettings.isSag === false && pplRoleId === '22a21fd3-9b01-4c07-a8a9-e70d17fbb4da') {
                        window.location = '/s4s/CustomPage/Index/' + Urls.page_caseManagerDashboard;
                    } else {
                         // Get saved data from sessionStorage
                        var dashboarddata = sessionStorage.getItem('dashboard');
                        if ( dashboarddata === 'legacy') {
                            window.location = '/s4s/CustomPage/Index/39';
                        } else {
                            window.location = '/s4s/CustomPage/Index/' + Urls.page_sagDashboard;
                        }
                    }

                //                         Case manager role and                                   Virtual support team members (CM Org admin) and         Support Broker (Self directed liason)                                                                           MCO admin                                               CM Admin
                } else if ( pplRoleId === 'a10d2183-fd39-4b1f-86f2-75cb89902a70' || pplRoleId === '1748741d-41d4-42a4-af02-f25ede425991' || pplRoleId === '05ec467c-6935-48a6-9b51-5b296b22f098' || pplRoleId === '3915a945-7676-4d43-bf72-41769ed07196' || pplRoleId === 'ce9c233a-cdde-41c4-bdd3-8b8a3cede36d' || pplRoleId === '781e55e1-43da-41da-a59b-29d2d28be586') {
                    window.location = '/s4s/CustomPage/Index/' + Urls.page_caseManagerDashboard;

                // New Jersey
                } else if ( orgId === 'b75b1eea-4f85-4673-aaef-ab1f00f1e36e') {
                    window.location = '/s4s/MyInvoices';   
                        
                } else {

                    if ( orgId !== null) {
                        
                        // Redirect to Agency dashboard (Vendor)
                        $.getJSON('/s4s/api/PPL/GetOrganisation/' + data.OrganisationId).done(function (organisation) {

                            providerType = organisation.Data.ProviderType;
                            if ( organisation.Data.ProviderType === '2cde0c21-b2fe-422e-99e6-be8816ef2ee7') {
                                window.location = '/s4s/CustomPage/Index/' + Urls.page_agencyDashboard;
                            }

                            // Is the user a transfer user?
                            $.getJSON('/s4s/api/Enrollment/EmployerEnrollmentsList?page=0').done(function (enroll) {
                    
                                if ( enroll.Data.Items.length !== 0) {
                                    var thisstatus = enroll.Data.Items[0].StatusStr;
                                    transferuser = thisstatus === 'Transferred' ? true : false;
                                }


                                // Provider role
                                if ( pplRoleId === '112c3cc7-2c81-4702-a988-ec2c1bb03cb9') {

                                    $.getJSON('/s4s/api/HiringProgram/PrincipalPrograms').done(function (data) {
                                        
                                        getProviderTiles(data, providerType);
                                    });


                                    searchThis();

                                    // Add in Jumbotron text
                                    if ( transferuser === false ) {    
                                        $('.js-homepage-jumbotron').append(
                                            '<p>Now that you have registered for MyAccount, please do the following to complete your enrollment.</p>' +
                                            '<ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.pplfirst.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Add your electronic signature under My Signature</div>' +
                                                '</li>' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Complete your Enrollment documents under My Programs</div>' +
                                                '</li>' +
                                            '</ul>' +
                                            '<p>Once these items are completed, you will be able to start! If you have any questions, please see the contact us form at the bottom of each page.</p>'
                                        )
                                    }

                                    if ( pplRoleId === '13fd06d4-112a-40db-bf90-eee6a3ac36db' || pplRoleId === '94f84bd5-e966-46f8-b545-aa29bd4f4e9e')
                                    {
                                        $('.steps-list-container').append(
                                        '<div class="dbCards continue-card">' +
                                            '<a href="/s4s/CustomPage/Index/' + Urls.page_agencyPaymentDetails + '">' +
                                                '<i class="fas fa-check"></i>' +
                                                '<h1>Payment details</h1>' +
                                                '<span class="card-btn">View</span>' +
                                            '</a>' +
                                        '</div>'   
                                        )

                                    }

                                // Auth rep role
                                } else if ( pplRoleId === '0062582f-f438-4c71-8626-8a1fec2a637c') {
                                    
                                    $('.js-hide-dashboard').show();
                                    $('.js-dashboard').show();
                                    searchThis();

                                    // Add in Jumbotron text
                                    if ( transferuser === false ) {
                                        $('.js-homepage-jumbotron').append(
                                            '<p>Now that you have registered for MyAccount, please do the following items to complete your enrollment.</p>' +
                                            '<ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.pplfirst.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Add your electronic signature under My Signature</div>' +
                                                '</li>' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Complete your Enrollment documents under My Participants</div>' +
                                                '</li>' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Review your account and contact details under My Details</div>' +
                                                '</li>' +
                                            '</ul>' +
                                            '<p>Once these set up items are completed, you will be able to <strong>start!</strong></p>'
                                        )
                                    }

                                // 13fd06d4-112a-40db-bf90-eee6a3ac36db - Independent Provider   ||  94f84bd5-e966-46f8-b545-aa29bd4f4e9e - Independent Contractor
                                } else if ( pplRoleId === '13fd06d4-112a-40db-bf90-eee6a3ac36db' || pplRoleId === '94f84bd5-e966-46f8-b545-aa29bd4f4e9e') {

                                    searchThis();

                                    // Add in Jumbotron text
                                    if ( transferuser === false ) {    
                                        $('.js-homepage-jumbotron').append(
                                            '<p>Now that you have registered for MyAccount, please do the following to complete your enrollment.</p>' +
                                            '<ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.pplfirst.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Add your electronic signature under My Signature</div>' +
                                                '</li>' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Complete your Enrollment documents under My Programs</div>' +
                                                '</li>' +
                                            '</ul>' +
                                            '<p>Once these items are completed, you will be able to start! If you have any questions, please see the contact us form at the bottom of each page.</p>'
                                        )
                                    }


                                    $('.steps-list-container').append(
                                        '<div class="dbCards continue-card">' +
                                            '<a href="/s4s/CustomPage/Index/' + Urls.page_agencyPaymentDetails + '">' +
                                                '<i class="fas fa-check"></i>' +
                                                '<h1>Payment details</h1>' +
                                                '<span class="card-btn">View</span>' +
                                            '</a>' +
                                        '</div>'   
                                    )


                                } else {
                                    $('.js-hide-dashboard').show();
                                    $('.js-dashboard').show();
                                    searchThis();
                                }

                            });
                            
                        // Some Ohio providers are getting a 500 error accessing the /s4s/api/PPL/GetOrganisation/ API
                        }).fail(function (organisation) {

                            // Is the user a transfer user?
                            $.getJSON('/s4s/api/Enrollment/EmployerEnrollmentsList?page=0').done(function (enroll) {
                    
                                if ( enroll.Data.Items.length !== 0) {
                                    var thisstatus = enroll.Data.Items[0].StatusStr;
                                    transferuser = thisstatus === 'Transferred' ? true : false;
                                }


                                // Provider role
                                if ( pplRoleId === '112c3cc7-2c81-4702-a988-ec2c1bb03cb9') {


                                    $.getJSON('/s4s/api/HiringProgram/PrincipalPrograms').done(function (data) {

                                        getProviderTiles(data, providerType);                                            
                                    });


                                    searchThis();

                                    // Add in Jumbotron text
                                    if ( transferuser === false ) {    
                                        $('.js-homepage-jumbotron').append(
                                            '<p>Now that you have registered for MyAccount, please do the following to complete your enrollment.</p>' +
                                            '<ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.pplfirst.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Add your electronic signature under My Signature</div>' +
                                                '</li>' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Complete your Enrollment documents under My Programs</div>' +
                                                '</li>' +
                                            '</ul>' +
                                            '<p>Once these items are completed, you will be able to start! If you have any questions, please see the contact us form at the bottom of each page.</p>'
                                        )
                                    }

                                // Auth rep role
                                } else if ( pplRoleId === '0062582f-f438-4c71-8626-8a1fec2a637c') {
                                    
                                    $('.js-hide-dashboard').show();
                                    $('.js-dashboard').show();
                                    searchThis();

                                    // Add in Jumbotron text
                                    if ( transferuser === false ) {
                                        $('.js-homepage-jumbotron').append(
                                            '<p>Now that you have registered for MyAccount, please do the following items to complete your enrollment.</p>' +
                                            '<ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.pplfirst.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Add your electronic signature under My Signature</div>' +
                                                '</li>' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Complete your Enrollment documents under My Participants</div>' +
                                                '</li>' +
                                                '<li class="pcg-homepage-jumbotron__item">' +
                                                    '<div class="pcg-rte-wrapper">Review your account and contact details under My Details</div>' +
                                                '</li>' +
                                            '</ul>' +
                                            '<p>Once these set up items are completed, you will be able to <strong>start!</strong></p>'
                                        )
                                    }


                                } else {
                                    $('.js-hide-dashboard').show();
                                    $('.js-dashboard').show();
                                    searchThis();
                                }

                            });

                        });
                    }
                }


                
            // If not logged in
            } else {

                if ( $.sc.authSettings.isSupportBroker === true ) {

                    window.location = '/s4s/CustomPage/Index/' + Urls.page_caseManagerDashboard;

                } else {

                    $('.js-hide-dashboard').show();
                    $('.js-login').show();

                    $('.js-homepage-jumbotron').append(
                        '<small style="font-size: 1rem; text-transform: none; display: block;">If you\'re getting lost then <a style="color:white;" href="/s4s/CustomPage/Index/19">please see our user guides here</a>.</small>'
                    );
                }

            }
        });
    
    }

   

    function getEnrollment(response) { 

        var item = response.Data;


        // Auth rep user
        if ( pplRoleId === '0062582f-f438-4c71-8626-8a1fec2a637c' ) {
            $('.js-auth-rep-card').hide();
            $('.js-employees-card').hide();
            $('.js-programs-card').hide();
            $('.js-participant-card').show();
            // My Auth Rep
            var myAuthRepDash = item.IsMyAuthRepCompleted;
            if ( myAuthRepDash === true) {
                $('.js-participant-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myParticipants + '">' +
                        '<i class="fas fa-check"></i> <h1>My participants</h1>' +
                        '<span class="card-btn">Manage</span>' +
                    '</a>'
            
                );
                $('.js-participant-card').addClass('review-card');
            
            } else {
                $('.js-participant-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myParticipants + '">' +
                        '<i class="fas fa-check"></i> <h1>My participants</h1>' +
                        '<span class="card-btn">Start</span>' +
                    '</a>'
            
                );
                $('.js-participant-card').addClass('continue-card');
            }
            
        }

      
        // Employer (My programs) /  Employee (My participant/ employer )
        var myPrograms = item.MyPrograms;

        var role = thisrole === "employer" ? "My Enrollment" : "My Participant/Employer";

        var programProgress = myPrograms.InProgressCount;
        var programCompleted = myPrograms.CompletedCount;

        var completedDoc = '';

        
        if (programProgress > programCompleted) {
            completedDoc = 'progress';
            
        } else if (programProgress === 0 && programCompleted === 0) {
            completedDoc = 'incomplete';

        } else if ( programProgress === programCompleted) {
            completedDoc = 'completed';

        } else if ( programProgress < programCompleted) {
            completedDoc = 'completed';
        }
        
        if ( programName !== 'OhioRISE' ) {
            if ( completedDoc === 'completed') {

            
                $('.js-programs-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myPrograms + '">' +
                        '<i class="fas fa-check"></i> <h1>' + role + '</h1>' +
                        '<span class="card-btn">Change</span>' +
                    '</a>'
                );
                $('.js-programs-card').addClass('review-card');


            } else if ( completedDoc === 'progress' ) {


                $('.js-programs-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myPrograms + '">' +
                        '<i class="fas fa-edit"></i> <h1>' + role + '</h1>' +
                        '<span class="card-btn">Continue</span>' +
                    '</a>'
                );
                $('.js-programs-card').addClass('amber-card');

            } else {

                $('.js-programs-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myPrograms + '">' +
                        '<i class="fas fa-edit"></i> <h1>' + role + '</h1>' +
                        '<span class="card-btn">Start</span>' +
                    '</a>'
                );
                $('.js-programs-card').addClass('continue-card');

            }
        } else {
            $('.js-programs-card').hide();
        }




        
        // My details
        var myDetailsDash = item.IsMyDetailsCompleted;
        if ( myDetailsDash === true) {
            $('.js-my-details-card').html(
                '<a href="/s4s/CustomPage/Index/' + Urls.page_myDetails + '">' +
                    '<i class="fas fa-check"></i> <h1>My details</h1>' +
                    '<span class="card-btn">View</span>' +
                '</a>'
           
            );
            $('.js-my-details-card').addClass('review-card');
        
        } else {
            $('.js-my-details-card').html(
                '<a href="/s4s/CustomPage/Index/' + Urls.page_myDetails + '">' +
                    '<i class="fas fa-check"></i> <h1>My details</h1>' +
                    '<span class="card-btn">Start</span>' +
                '</a>'
           
            );
            $('.js-my-details-card').addClass('continue-card');
        }


        // Get Signature data
        var signatureDash = item.IsMySignatureCompleted;
        if ( ($.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== '101364f3-1bf8-4f12-83c9-ad2f00947631')
            && (programName !== 'OhioRISE') ) {
            if ( signatureDash === true) {
                $('.js-signature-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_mySignatures + '">' +
                        '<i class="fas fa-check"></i>' +
                        '<h1>My signature</h1>' +
                        // '<span class="card-step-counter">1/1<br><small>Steps complete</small></span>' +
                        '<span class="card-btn">Change</span>' +
                    '</a>'
                );
                $('.js-signature-card').addClass('review-card');
            
            } else {
    
                $('.js-signature-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_mySignatures + '">' +
                        '<i class="far fa-play-circle"></i>' +
                        '<h1>My signature</h1>' +
                        // '<span class="card-step-counter">0/1<br><small>Steps complete</small></span>' +
                        '<span class="card-btn">Add my signature</span>' +
                    '</a>'
                );
                $('.js-signature-card').addClass('continue-card');
            }
        } else {
            $('.js-signature-card').remove()
        }



        // Employer only items
        if ( thisrole === 'employer') {

            // Auth Rep
            var authRepDash = item.IsMyAuthRepCompleted;
            if ( programName !== 'OhioRISE' ) {
              let myRepTileName = 'My designated representative';
              if (programName === 'Tennessee AC' || programName === 'Tennessee AG') {
                myRepTileName = 'My representatives';
              }
              if ( authRepDash === true ) {

                  $('.js-auth-rep-card').html(
                      '<a href="/s4s/CustomPage/Index/' + Urls.page_myDesignatedRep + '">' +
                          '<i class="fas fa-check"></i> <h1>' + myRepTileName + '</h1>' +
                          '<span class="card-btn">Change</span>' +
                      '</a>'
                  );
                  $('.js-auth-rep-card').addClass('review-card');

              } else {

                  $('.js-auth-rep-card').html(
                      '<a href="/s4s/CustomPage/Index/' + Urls.page_myDesignatedRep + '">' +
                          '<i class="fas fa-edit"></i> <h1>' + myRepTileName + '</h1>' +
                          '<span class="card-btn">Start</span>' +
                      '</a>'
                  );
                  $('.js-auth-rep-card').addClass('continue-card');
              }
            } else {
                $('.js-auth-rep-card').hide();
            }
            


            // My Employees
            var myEmployees = item.MyEmployees;


            var employeesProgress = myEmployees.InProgressCount;
            var employeesCompleted = myEmployees.CompletedCount;

            var completedEmployees = '';

            if (employeesProgress === 0 && employeesCompleted === 0) {
                completedEmployees = 'incomplete'

            } else if ( employeesProgress === employeesCompleted) {
                completedEmployees = 'completed'
            
            } else if (employeesProgress > employeesCompleted) {
                completedEmployees = 'progress'

            }

            
            if ( completedEmployees === 'completed' ) {

            
                $('.js-employees-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myProviders + '">' +
                        '<i class="fas fa-check"></i> <h1>My providers</h1>' +
                        '<span class="card-btn">Change</span>' +
                    '</a>'
                );
                $('.js-employees-card').addClass('review-card');

            } else if ( completedEmployees === 'progress' ) {


                $('.js-employees-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myProviders + '">' +
                        '<i class="fas fa-edit"></i> <h1>My providers</h1>' +
                        '<span class="card-btn">Continue</span>' +
                    '</a>'
                );
                $('.js-employees-card').addClass('amber-card');



            } else {

                $('.js-employees-card').html(
                    '<a href="/s4s/CustomPage/Index/' + Urls.page_myProviders + '">' +
                        '<i class="fas fa-edit"></i> <h1>My providers</h1>' +
                        '<span class="card-btn">Start</span>' +
                    '</a>'
                );
                $('.js-employees-card').addClass('continue-card');
            }

        }



    }


    function getSupportTileTile() {

        let thisMCO = '';
        let thisSF = '';

        fetchMcoJSON().then(mco => {
            mco; // fetched mco
            console.log('mco is: ' + mco.Data.MCO);

            if ( mco.Data.MCO !== '') {
                thisMCO = `<p><strong>Associated MCO: </strong><br> ${mco.Data.MCO}</p>`;
            }

            fetchSFJSON().then(sf => {
                sf; // fetched sf

                if ( sf.Data.Items.length >= 1) {
                    thisSF = `<p style="margin-bottom: 0;"><strong>Associated Service Facilitator: </strong><br> ${sf.Data.Items[0].OrganisationName}</p>`;
                }
                //console.log('sf is: ' + sf.Data.MCO)

                $('.steps-list-container').append(
                    '<div class="dbCards continue-card">' +
                        '<div>' +
                            '<h1>My support team</h1>' +
                            thisMCO +
                            thisSF +
                        '</div>' +
                    '</div>'
                )
            });
        });
    }


    async function fetchMcoJSON() {
        const response = await fetch('/s4s/api/PPLUser/Get/' + $.sc.authSettings.id);
        const mco = await response.json();
        return mco;
    }

    async function fetchSFJSON() {
        const response = await fetch('/s4s/api/PPL/GetOrganisations?organisationType=ServiceFacilitator&businessviewcode=510df899-0216-4709-998a-507fc5c2a3e9');
        const sf = await response.json();
        return sf;
    }


    var Attributes = {
        init: function() {
            this.adjustDom();
            this.bindUIActions();
            this.unload();
            checkAuth();

        },

        adjustDom: function() {

        },

        bindUIActions: function() {

        },

        unload: function() {

        }
    };

    Attributes.init();

})();
